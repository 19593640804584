import i18n from "@/plugins/i18n";
import Enum from "@/_helpers/CsharpEnum";

export default {
  Enum: {
    ICON: "so_icon",
    VALUEMATCH: "so_value_match",
    SUFIX: "so_sufix",
    PREFIX: "so_prefix",
    DEVEUI: "so_deveui_key",
    MAXHEIGHT: "so_max_height",
    SCRIPTID: "so_script_id",
    TARGET: "so_target",
    TAGKEY: "so_tagkey",
    TAGKEY_X: "so_tagkey_x",
    TAGKEY_Y: "so_tagkey_y",
    TAGKEY_X_MAX: "so_tagkey_x_max",
    TAGKEY_Y_MAX: "so_tagkey_y_max",
    DECIMAL_ROUNDING: "so_decimal_rounding",
    DASHBOARDID: "so_dashboard_id",
    SYNC_Y: "so_sync_y",
    ZOOM_SLIDER: "so_zoom_slider",
    DIGITAL: "so_digital",
    ALARM_ID: "so_alarm_id",
    CHART_TYPE: "so_chart_type",
    MIN: "so_min_value",
    MAX: "so_max_value",
    AXIS_LABEL: "so_axis_label",
    DEFAULT_TIMESPAN: "so_default_timespan",
    HIDETRENDICON: "so_hide_trend_icon",
    HORIZONTALSINGLETITLE: "so_horizontel_single_title",
    SINGLEHIDENODEINFO: "so_hide_node_info",
    FONT_WEIGHT: "so_font_weight",
    FONT_STYLE: "so_font_style_large",
    FONT_STYLE_TITLE: "so_font_style_title",
    FONT_STYLE_CONTENT: "so_font_style_content",
    SINGLESPARKLINE: "so_single_sparkline",
    FN_BTN: "so_functionbutton_type",
    DOWNLINK: "so_functionbutton_downlink",
    DOWNLINK_PAYLOAD: "so_functionbutton_downlink_payload",
    DOWNLINK_PORT: "so_functionbutton_downlink_port",
    DOWNLINK_CONFIRMED: "so_functionbutton_downlink_confirmed",
    GAUGE180: "so_180_gauge",
    BUTTONLABEL: "so_button_label",
    BUTTONLABEL_ON: "so_button_label_on",
    BUTTONLABEL_OFF: "so_button_label_off",
    HIDETITLE: "so_hide_title",
    ONOFFSWITCH: "so_functionbutton_onoff",
    VALUESLIDER: "so_value_slider",
    CAMONLINE: "camonline_camera",
    OWN_AXIS: "so_own_axis",
    VERTICAL: "so_vertical_chart",

    SLOTS_COUNT: "so_slots_count",

    IMAGEMAP_MARKERS: "so_imagemap_markers",
    IMAGEMAP_NODE: "so_imagemap_node",
    IMAGEMAP_NODE_KEY: "so_imagemap_node_key",
    IMAGEMAP_FONTSTYLE: "so_imagemap_fontstyle",
    IMAGEMAP_ICON: "so_imagemap_icon",
    IMAGEMAP_ICON_WIDTH: "so_imagemap_icon_width",
    IMAGEMAP_ICON_HEIGHT: "so_imagemap_icon_height",
    IMAGEMAP_ICON_ANCHOR_X: "so_imagemap_icon_anchor_x",
    IMAGEMAP_ICON_ANCHOR_Y: "so_imagemap_icon_anchor_y",
    IMAGEMAP_ICON_COLOR_BACKGROUND: "so_imagemap_icon_color_background",
    IMAGEMAP_ICON_COLOR_BORDER: "so_imagemap_icon_color_border",
    IMAGEMAP_ICON_COLOR_TEXT: "so_imagemap_icon_color_text",
    IMAGEMAP_ACTION_FOR_VALUE: "so_imagemap_action_for_value",
  },

  Keys: [
    { key: "so_own_axis", name: i18n.t("module.meta.keys.so_own_axis") },
    { key: "camonline_camera", name: i18n.t("camOnline.servers") },
    { key: "so_value_match", name: i18n.t("module.meta.keys.value_match") },
    { key: "so_icon", name: i18n.t("module.meta.keys.icon") },
    { key: "so_sufix", name: i18n.t("module.meta.keys.sufix") },
    { key: "so_prefix", name: i18n.t("module.meta.keys.prefix") },
    { key: "so_deveui_key", name: i18n.t("module.meta.keys.deveui") },
    { key: "so_max_height", name: i18n.t("module.meta.keys.maxheight") },
    { key: "so_script_id", name: i18n.t("module.meta.keys.scriptid") },
    { key: "so_target", name: i18n.t("module.meta.keys.target") },
    { key: "so_tagkey", name: i18n.t("module.meta.keys.tagkey") },
    { key: "so_tagkey_x", name: i18n.t("module.meta.keys.tagkey_x") },
    { key: "so_tagkey_y", name: i18n.t("module.meta.keys.tagkey_y") },
    { key: "so_tagkey_x_max", name: i18n.t("module.meta.keys.tagkey_x_max") },
    { key: "so_tagkey_y_max", name: i18n.t("module.meta.keys.tagkey_y_max") },
    { key: "so_force_colors", name: i18n.t("module.meta.keys.forceColors") },
    { key: "so_vertical_chart", name: i18n.t("module.chart.types.vertical") },
    {
      key: "so_decimal_rounding",
      name: i18n.t("module.meta.keys.decimal_rounding"),
    },
    {
      key: "so_dashboard_id",
      name: i18n.t("module.meta.keys.dashboard_id"),
    },
    { key: "so_sync_y", name: i18n.t("module.meta.keys.sync_y") },
    { key: "so_zoom_slider", name: i18n.t("module.meta.keys.zoom_slider") },
    { key: "so_digital", name: i18n.t("module.meta.keys.digital") },
    { key: "so_alarm_id", name: i18n.t("module.meta.keys.alarm_id") },
    { key: "so_chart_type", name: i18n.t("module.meta.keys.so_chart_type") },
    { key: "so_ignore_body", name: i18n.t("module.meta.keys.so_ignore_body") },
    { key: "so_min_value", name: i18n.t("module.meta.keys.min_value") },
    { key: "so_max_value", name: i18n.t("module.meta.keys.max_value") },
    { key: "so_axis_label", name: i18n.t("module.meta.keys.axis_label") },
    {
      key: "so_default_timespan",
      name: i18n.t("module.meta.keys.default_timespan"),
    },
    {
      key: "so_hide_trend_icon",
      name: i18n.t("module.meta.keys.hide_trend_icon"),
    },
    {
      key: "so_horizontel_single_title",
      name: i18n.t("module.meta.keys.horizontel_single_title"),
    },
    {
      key: "so_hide_node_info",
      name: i18n.t("module.meta.keys.so_hide_node_info"),
    },
    {
      key: "so_font_style_title",
      name: i18n.t("module.meta.keys.font_style_title"),
    },
    {
      key: "so_font_style_content",
      name: i18n.t("module.meta.keys.font_style_content"),
    },
    {
      key: "so_font_style_large",
      name: i18n.t("module.meta.keys.font_style_large"),
    },
    {
      key: "so_font_weight",
      name: i18n.t("module.meta.fields.font_style_large.weight"),
    },

    {
      key: "so_single_sparkline",
      name: i18n.t("module.meta.keys.so_single_sparkline"),
    },
    {
      key: "so_slots_count",
      name: i18n.t("module.meta.keys.so_slots_count"),
    },
    {
      key: "so_180_gauge",
      name: i18n.t("module.meta.keys.gauge_180"),
    },
    {
      key: "so_button_label",
      name: i18n.t("module.meta.keys.button_label"),
    },
    {
      key: "so_button_label_on",
      name: i18n.t("module.meta.keys.button_label_on"),
    },
    {
      key: "so_button_label_off",
      name: i18n.t("module.meta.keys.button_label_off"),
    },
    {
      key: "so_hide_title",
      name: i18n.t("module.meta.keys.hide_title"),
    },
  ],

  InputFields: {
    // Fields: [value, value_match, icon, tag, bool-value]
    // psm = Per Slot Meta
    so_value_match: {
      fieldsLabels: [
        i18n.t("module.meta.fields.value_match"),
        i18n.t("module.meta.fields.name"),
      ],
      fields: [true, true, false, false, false],
    },
    so_icon: {
      fieldsLabels: [i18n.t("module.meta.fields.icon"), null],
      fields: [false, false, true, false, false],
    },
    so_sufix: {
      fieldsLabels: [i18n.t("module.meta.fields.sufix"), null],
      fields: [true, false, false, false, false],
    },
    so_prefix: {
      fieldsLabels: [i18n.t("module.meta.fields.prefix"), null],
      fields: [true, false, false, false, false],
    },
    so_deveui_key: {
      fieldsLabels: [i18n.t("module.meta.fields.deveui"), null],
      fields: [false, false, false, true, false],
    },
    so_max_height: {
      fieldsLabels: [i18n.t("module.meta.fields.maxheight"), null],
      fields: [true, false, false, false, false],
    },
    so_script_id: {
      fieldsLabels: [i18n.t("module.meta.fields.scriptid"), null],
      fields: [false, false, false, false, false],
    },
    so_target: {
      fieldsLabels: [
        i18n.t("module.meta.fields.target"),
        i18n.t("module.meta.fields.target"),
      ],
      fields: [false, true, false, false, false],
    },
    so_tagkey: {
      fieldsLabels: [i18n.t("module.meta.fields.tagkey"), null],
      fields: [false, false, false, false, false],
    },
    so_tagkey_x: {
      fieldsLabels: [i18n.t("module.meta.fields.tagkey_x"), null],
      fields: [false, false, false, false, false],
    },
    so_tagkey_y: {
      fieldsLabels: [i18n.t("module.meta.fields.tagkey_y"), null],
      fields: [false, false, false, false, false],
    },
    so_tagkey_x_max: {
      fieldsLabels: [i18n.t("module.meta.fields.tagkey_x_max"), null],
      fields: [false, false, false, false, false],
    },
    so_tagkey_y_max: {
      fieldsLabels: [i18n.t("module.meta.fields.tagkey_y_max"), null],
      fields: [false, false, false, false, false],
    },
    so_force_colors: {
      fieldsLabels: [i18n.t("module.meta.fields.forceColors"), null],
      fields: [false, false, false, false, true],
    },
    so_decimal_rounding: {
      fieldsLabels: [i18n.t("module.meta.fields.decimal_rounding"), null],
      fields: [true, false, false, false, false],
    },
    so_dashboard_id: {
      fieldsLabels: [i18n.t("module.meta.fields.dashboard_id"), null],
      fields: [false, false, false, false, false],
    },
    so_sync_y: {
      fieldsLabels: [i18n.t("module.meta.keys.sync_y"), null],
      fields: [false, false, false, false, true],
    },
    so_own_axis: {
      fieldsLabels: [i18n.t("module.meta.keys.so_own_axis"), null],
      fields: [false, false, false, false, true],
      psm: true,
    },
    so_zoom_slider: {
      fieldsLabels: [i18n.t("module.meta.keys.zoom_slider"), null],
      fields: [false, false, false, false, true],
    },
    so_digital: {
      fieldsLabels: [i18n.t("module.meta.keys.digital"), null],
      fields: [false, false, false, false, true],
      psm: true,
    },
    so_alarm_id: {
      fieldsLabels: [i18n.t("module.meta.keys.alarm_id"), null],
      fields: [false, false, false, false, false],
    },
    so_chart_type: {
      fieldsLabels: [i18n.t("module.meta.keys.so_chart_type"), null],
      fields: [false, false, false, false, false],
      psm: true,
    },
    so_ignore_body: {
      fieldsLabels: [i18n.t("module.meta.keys.so_ignore_body"), null],
      fields: [false, false, false, false, true],
    },
    so_min_value: {
      fieldsLabels: [i18n.t("module.meta.fields.min_value"), null],
      fields: [true, false, false, false, false],
      psm: true,
    },
    so_max_value: {
      fieldsLabels: [i18n.t("module.meta.fields.max_value"), null],
      fields: [true, false, false, false, false],
      psm: true,
    },
    so_axis_label: {
      fieldsLabels: [i18n.t("module.meta.fields.axis_label"), null],
      fields: [true, false, false, false, false],
      psm: true,
    },
    so_default_timespan: {
      fieldsLabels: [i18n.t("module.meta.fields.default_timespan"), null],
      fields: [false, false, false, false, false],
    },
    so_vertical_chart: {
      fieldsLabels: [i18n.t("module.chart.types.vertical"), null],
      fields: [false, false, false, false, true],
    },
    so_hide_trend_icon: {
      fieldsLabels: [i18n.t("module.meta.keys.hide_trend_icon"), null],
      fields: [false, false, false, false, true],
    },
    so_horizontel_single_title: {
      fieldsLabels: [i18n.t("module.meta.keys.horizontel_single_title"), null],
      fields: [false, false, false, false, true],
    },
    so_hide_node_info: {
      fieldsLabels: [i18n.t("module.meta.keys.so_hide_node_info"), null],
      fields: [false, false, false, false, true],
    },
    so_font_style_large: {
      fieldsLabels: [i18n.t("module.meta.keys.font_style_large"), null],
      fields: [false, false, false, false, false],
    },
    so_font_weight: {
      fieldsLabels: [
        i18n.t("module.meta.fields.font_style_large.weight"),
        null,
      ],
      fields: [false, false, false, false, false],
    },
    so_single_sparkline: {
      fieldsLabels: [i18n.t("module.meta.keys.so_single_sparkline"), null],
      fields: [false, false, false, false, true],
    },
    so_slots_count: {
      fieldsLabels: [i18n.t("module.meta.keys.so_slots_count"), null],
      fields: [false, false, false, false, false],
      numberInput: true,
    },
    so_180_gauge: {
      fieldsLabels: [i18n.t("module.meta.keys.gauge_180"), null],
      fields: [false, false, false, false, true],
    },
    so_button_label: {
      fieldsLabels: [i18n.t("module.meta.keys.button_label"), null],
      fields: [true, false, false, false, false],
    },
    so_button_label_on: {
      fieldsLabels: [i18n.t("module.meta.keys.button_label_on"), null],
      fields: [true, false, false, false, false],
    },
    so_button_label_off: {
      fieldsLabels: [i18n.t("module.meta.keys.button_label_off"), null],
      fields: [true, false, false, false, false],
    },
    so_hide_title: {
      fieldsLabels: [i18n.t("module.meta.keys.hide_title"), null],
      fields: [false, false, false, false, true],
    },
    so_font_style_title: {
      fieldsLabels: [i18n.t("module.meta.fields.so_font_style_title"), null],
      fields: [false, false, false, false, false],
    },
    so_font_style_content: {
      fieldsLabels: [i18n.t("module.meta.fields.so_font_style_content"), null],
      fields: [false, false, false, false, false],
    },
  },

  StyleFields: {
    // Fields: [colorpicker, precentilepicker, font picker]
    [Enum.ModuleType.GAUGE]: {
      fields: [true, true, false],
    },
    [Enum.ModuleType.CHART]: {
      fields: [true, false, false],
    },
    [Enum.ModuleType.TILTCHART]: {
      fields: [true, false, false],
    },
    [Enum.ModuleType.FILLINDICATOR]: {
      fields: [true, false, false],
    },
    [Enum.ModuleType.FUNCTIONBUTTON]: {
      fields: [true, false, false],
    },
    [Enum.ModuleType.IMAGEMAP]: {
      fields: [false, false, false],
    },
  },

  Widgets: {
    [Enum.ModuleType.CHART]: [],
    [Enum.ModuleType.GAUGE]: [
      "so_prefix",
      "so_sufix",
      "so_force_colors",
      "so_target",
      "so_180_gauge",
    ],
    [Enum.ModuleType.SINGLE]: [
      "so_deveui_key",
      "so_value_match",
      "so_prefix",
      "so_sufix",
      "so_icon",
      "so_decimal_rounding",
      "so_hide_trend_icon",
      "so_horizontel_single_title",
      "so_hide_node_info",
      "so_font_style_large",
      "so_font_weight",
      "so_single_sparkline",
      "so_slots_count",
      "so_font_style_title",
      "so_font_style_content",
    ],
    [Enum.ModuleType.TEXT]: ["so_ignore_body"],
    [Enum.ModuleType.FUNCTIONBUTTON]: [
      "so_button_label",
      "so_button_label_on",
      "so_button_label_off",
      "so_hide_title",
      "so_hide_node_info",
    ],
    [Enum.ModuleType.IMAGE]: ["so_font_style_title"],
    [Enum.ModuleType.MAP]: [],
    [Enum.ModuleType.COMPANYINFO]: [],
    [Enum.ModuleType.IFRAME]: [],
    [Enum.ModuleType.MULTICHART]: [
      "so_deveui_key",
      "so_sync_y",
      "so_own_axis",
      "so_zoom_slider",
      "so_digital",
      "so_alarm_id",
      "so_target",
      "so_chart_type",
      "so_min_value",
      "so_max_value",
      "so_axis_label",
      "so_default_timespan",
      "so_vertical_chart",
    ],
    [Enum.ModuleType.WEATHER]: [],
    [Enum.ModuleType.INFOCARD]: ["so_tagkey"],
    [Enum.ModuleType.TRENDCHART]: ["so_default_timespan"],
    [Enum.ModuleType.SENSOROVERVIEW]: [],
    [Enum.ModuleType.TILTCHART]: [
      "so_tagkey_x",
      "so_tagkey_y",
      "so_tagkey_x_max",
      "so_tagkey_y_max",
    ],
    [Enum.ModuleType.FILLINDICATOR]: ["so_alarm_id", "so_target"],
    [Enum.ModuleType.CAMONLINE]: ["camonline_camera"],
  },

  RegularDashboardIgnoreMeta: {
    [Enum.ModuleType.SINGLE]: [
      "so_font_weight",
      "so_slots_count",
      "so_font_style_title",
      "so_font_style_content",
    ],
    [Enum.ModuleType.CHART]: [],
    [Enum.ModuleType.GAUGE]: [],
    [Enum.ModuleType.TEXT]: [],
    [Enum.ModuleType.FUNCTIONBUTTON]: [],
    [Enum.ModuleType.IMAGE]: [],
    [Enum.ModuleType.MAP]: [],
    [Enum.ModuleType.COMPANYINFO]: [],
    [Enum.ModuleType.IFRAME]: [],
    [Enum.ModuleType.MULTICHART]: ["so_own_axis"],
    [Enum.ModuleType.WEATHER]: [],
    [Enum.ModuleType.INFOCARD]: [],
    [Enum.ModuleType.TRENDCHART]: [],
    [Enum.ModuleType.SENSOROVERVIEW]: [],
    [Enum.ModuleType.TILTCHART]: [],
    [Enum.ModuleType.FILLINDICATOR]: [],
    [Enum.ModuleType.CAMONLINE]: [],
  },

  Icons: [
    "mdi-temperature-celsius",
    "mdi-temperature-fahrenheit",
    "mdi-temperature-kelvin",
    "mdi-coolant-temperature",
    "mdi-account",
    "mdi-account-circle",
    "mdi-speedometer",
    "mdi-flash",
    "mdi-alarm-bell",
    "mdi-alarm",
    "mdi-alarm-light",
  ],

  methods: {
    metaHasKey(meta, key) {
      if (meta == null || meta.length <= 0) return false;

      for (let i = 0; i < meta.length; i++) {
        if (meta[i].key === key) return true;
      }

      return false;
    },

    getMeta(meta, key) {
      if (!meta) return null;
      for (let i = 0; i < meta.length; i++)
        if (meta[i].key === key) return meta[i];

      return null;
    },

    getDeveuis(meta) {
      let deveuis = [];
      for (let i = 0; i < meta.length; i++) {
        if (meta[i].key === "so_deveui_key") {
          deveuis.push(meta[i].value);
        }
      }

      return deveuis;
    },

    getDeveuisValueMatch(meta) {
      let deveuis = [];
      for (let i = 0; i < meta.length; i++) {
        if (meta[i].key === "so_deveui_key") {
          deveuis.push({ value: meta[i].value, match: meta[i].valueMatch });
        }
      }

      return deveuis;
    },
  },

  computed: {
    // "so_style_meta_color_background_card"
    soMetaBackgroundColorCard() {
      if (this.module == null || this.module.meta == null) return null;

      let meta = this.module.meta.find(
        (e) => e.key == "so_style_meta_color_background_card"
      );
      if (meta == null) return null;

      return meta.content;
    },

    // so_style_meta_color_text_title
    soMetaTextColorTitle() {
      if (this.module == null || this.module.meta == null) return null;

      let meta = this.module.meta.find(
        (e) => e.key == "so_style_meta_color_text_title"
      );
      if (meta == null) return null;

      return meta.content;
    },
  },
};
